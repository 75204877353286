import request from '@/utils/request'


// 巡防任务列表
export function patrolTaskList(params) {
  return request({
    url: '/patrolTask/page',
    method: 'get',
    params:params
  })
}

//新增任务
export function addTask(params) {
  return request({
    url: '/patrolTask/add',
    method: 'get',
    params:params
  })
}

//任务流水
export function taskFlow(params) {
  return request({
    url: '/patrolTask/taskLog',
    method: 'get',
    params:params
  })
}

//中止任务
export function taskEnd(params) {
  return request({
    url: '/patrolTask/end',
    method: 'get',
    params:params
  })
}





//任务详情
export function taskDetail(params) {
  return request({
    url: '/patrolTask/taskDetail',
    method: 'get',
    params:params
  })
}

//任务删除
export function taskDelete(data) {
	return request({
		url: '/patrolTask/TaskDel',
		method: 'get',
		params: data
	})
}

//总任务数
export function taskTotal(params) {
  return request({
    url: '/patrolTask/task',
    method: 'get',
    params:params
  })
}


//任务周期
export function taskPeriod(params) {
  return request({
    url: '/patrolTask/taskPeriod',
    method: 'get',
    params:params
  })
}



//巡防人员列表
export function xunfangPersonList(data) {
	return request({
		url: '/house/xunfangPersonList',
		method: 'get',
		params: data
	})
}


//流水导出
export function patrolTaskExport(data) {
	return request({
		url: '/patrolTask/exportList',
		method: 'post',
		responseType: "blob",
		params: data
	})
}


