<template>
	<div class="houselistbox">
		<div class="left" v-loading="loading1">
			<div class="tree">
				<el-tree :expand-on-click-node="false" :data="tree" class="filter-tree" :props="defaultProps" :default-expanded-keys="[1]"
					:default-checked-keys="[1]" :highlight-current="true" @node-click="nodeClick" ref="tree">
					<span class="my-custom" slot-scope="{ node, data }" style="width: 100%;height: 100%;">
						<span style="width: 100%;height: 100%;line-height: 32px;">{{ data.name }}</span>
					</span>
				</el-tree>
			</div>
		</div>
		<div class="right">
			<div class="transformer">
				<div class="clearfix electric-select">
					<el-form ref="ruleForm" :model="chaxun" :inline="true">
						<el-form-item label="房屋地址">
							<el-input class="shuru" v-model="chaxun.housename"></el-input>
						</el-form-item>
						<el-form-item label="网格名称">
							<el-input class="shuru" v-model="chaxun.grid_name"></el-input>
						</el-form-item>
						<el-form-item label="网格员名称">
							<el-input class="shuru" v-model="chaxun.gridmanname"></el-input>
						</el-form-item>
						<el-form-item >
							<el-button type="primary" @click="onsearch()">
								<i class="el-icon-search"></i>
								查询
							</el-button>
						</el-form-item >
					</el-form>

				</div>
				<div v-loading="loading2">
					<el-table ref="multipleTable" :data="tableData" border :row-key="getRowKeys" @selection-change="handleSelectionChange">
						<el-table-column
							type="selection"
							width="55"
							:reserve-selection="true">
						</el-table-column>
						<el-table-column prop="grid_name" label="网格名称" align="center" height show-overflow-tooltip>
						</el-table-column>
						<el-table-column prop="gridmanname" label="网格员名称" align="center" height show-overflow-tooltip>
						</el-table-column>
						<el-table-column prop="housename" label="房屋地址" align="center" height show-overflow-tooltip>
						</el-table-column>				
					</el-table>
					<div class="block">
						<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
							:current-page="currentPage" :page-sizes="fenye.pagesizes" :page-size="fenye.pagesize"
							layout="total, sizes, prev, pager, next, jumper" :total="fenye.totalnum"></el-pagination>
					</div>
					
					<div>
						<div v-if="multipleSelection.length>0" style="padding: 20px 0 15px; font-size: 18px; text-align: left">共选择<span style="padding:0 5px; color: #409eff; font-weight: bold;">{{ multipleSelection.length }}</span>条数据</div>
						<div style="text-align: right; margin-top: 40px; padding: 0 20px;">
							<el-button @click="cancelChooseAddress"  size="medium">取 消</el-button>
							<el-button @click="sureAddress" size="medium" type="primary">确 定</el-button>
						</div>
					</div>
				</div>
			</div>
		</div>

		
	</div>
</template>

<script>
	import {
		searchHouseNumsTree
	} from '@/api/shaicha'

	import { xunfangPersonList } from '@/api/patrolTask'
	export default {
		data() {
			return {
				chaxun: {
					housename:'',
					grid_name:'',
					gridmanname:''
				},
				time: new Date(),
				treeId: '',
				searchName: '',
				search: '',
				dialogTableVisible: false,
				tableData: [],
				currentPage: 1,
				fenye: {
					pagesizes: [10, 20, 30, 40],
					pagesize: 10,
					totalnum: 0
				},
				form: {
					name: '',
					status: '',
					remark: ''
				},
				defaultProps: {
					children: 'list',
					label: 'name'
				},
				treeSelectId: '',
				tree: [],
				newbutton: false,
				levelcode: '',
				xiugaiVisible: false,
				form2: {
					level: '',
					name: '',
					type: '',
					remark: '',
					id: ''
				},
				xiugaiLevelcode: '',
				gridid: '',
				loading1: true,
				loading2: false,
				mapVisible: false,
				form3: {
					netLocation: '',
					centerLocation: ''
				},
				house_code:'',
				multipleSelection: []

			};
		},
		mounted() {
			let that = this;
			that.getTree();
		},
		methods: {
			handleSelectionChange(val) {
				console.log(val)
        this.multipleSelection = val;
				
      },
			cancelChooseAddress(){
				this.$refs.multipleTable.clearSelection();
				this.$emit('closeAddressDialog', false)
			},
			sureAddress(){
				this.$emit('getAddressInfo',this.multipleSelection)
			},
			getRowKeys(row) {
				return row.houseid
			},
			getTree() {
				this.loading1 = true;
				searchHouseNumsTree({
					'x-access-token': this.GLOBAL.token(),
					userid: this.GLOBAL.adminId()
				}).then((res) => {
					this.loading1 = false;
					if (res.result == '200') {
						let tree = res.detail.tree;
						for(let item1 of tree){
							let num1 = item1.showpersonnums == undefined?'':'(' + item1.showpersonnums + ')';
							item1.name += num1;
							item1.list = item1.regionlist;
							for(let item2 of item1.list){
								let num2 = item2.showpersonnums == undefined?'':'(' + item2.showpersonnums + ')';
								item2.name = item2.regionname + num2;
								item2.list = item2.buildlist;
								for(let item3 of item2.list){
									let num3 = item3.showpersonnums == undefined?'':'(' + item3.showpersonnums + ')';
									item3.name = item3.buildname + num3;
									if(item3.housedanyuan){
										item3.list = item3.housedanyuan;
										for(let item4 of item3.list){
											let num4 = item4.showpersonnums == undefined?'':'(' + item4.showpersonnums + ')';
											item4.name = item4.housenum + num4;
											
										}
									}
									
								}
							}
						}
						this.tree = tree;
					} else {
						this.$message.error(res.description);
					}
				})
			},
			handleSizeChange(val) {
				this.fenye.pagesize = val;
				this.currentPage = 1;
				this.getList();
			},
			handleCurrentChange(val) {
				this.currentPage = val;
				this.getList();
			},
			nodeClick(data) {
				console.log(data);
				var that = this;
				that.tableData = [];
				that.currentPage = 1;
				that.house_code = data.house_code;
				that.getList();
			},
			onsearch() {
				this.currentPage = 1;
				this.search = this.searchName;
				this.getList();
			},
			getList() {
				var that = this;
				that.loading2 = true;
				xunfangPersonList({
					'x-access-token': this.GLOBAL.token(),
					"userid":this.GLOBAL.adminId(),
					housecode: this.house_code,
					housename: this.chaxun.housename,
					grid_name: this.chaxun.grid_name,
					gridmanname: this.chaxun.gridmanname,
					page: that.currentPage,
					pagesize: that.fenye.pagesize,
				}).then((res) => {
					that.loading2 = false;
					if (res.result == '200') {
						that.tableData = res.detail.list;
						that.fenye.totalnum = res.detail.totalRow;
					} else {
						that.$message.error(res.description);
					}
				})
			},
		}
	};
</script>

<style lang="scss" scoped="scoped">
	.block {
		padding: 20px 0 0;
	}
	.right .transformer {
		box-sizing: border-box;
		height: calc(100% - 227px);
		overflow-y: auto;
	}
	.topbox{
		height: 167px;
		margin-bottom: 28px;
	}
	.houselistbox {
		display: flex;
		justify-content: space-between;
		.left {
			width: 220px;
			height: 540px;
			overflow: auto;
			box-sizing: border-box;
			border: solid 1px #d7dde4;

			.search {
				width: 100%;
				box-sizing: border-box;
				padding: 16px;
				border-bottom: solid 1px #d7dde4;
			}

			& /deep/ .el-tree {
				.el-tree-node__content {
					height: 32px;
				}

				.el-icon-caret-right {
					width: 14px;
					text-align: center;
					padding-left: 16px;
					padding-right: 8px;
				}

				.el-icon-caret-right:not(.expanded):before {
					content: '+';
					font-size: 18px;
					font-weight: bold;
					transform: none;
					color: #848694;
				}

				.el-tree-node__expand-icon.expanded {
					transform: rotate(0deg);
				}

				.el-tree-node__expand-icon.expanded:before {
					content: '-';
					font-size: 18px;
					font-weight: bold;
					color: #848694;
				}

				.is-leaf {
					opacity: 0;
				}

				.my-custom {
					font-size: 14px;

					& * {
						display: inline-block;
						vertical-align: middle;
					}
				}
			}
		}

		.right {
			width: calc(100% - 240px);
			height: 100%;
		}
	}

	

	.form3style {
		padding: 0 20px;

		.el-form-item {
			.spanstyle {
				padding-left: 25px;
				font-size: 20px;
				background: url(../../assets/imgs/edit.png) no-repeat left center;
				background-size: 20px auto;
				color: #5B93C1;

				&:hover {
					text-decoration: underline;
				}
			}
		}
	}
	.detailtable{
		overflow: hidden;
		border-top: 1px solid #e3e4e3;
		border-left: 1px solid #e3e4e3;
		margin-bottom: 20px;
	}
	.detailtable p,
	.detailtable div{
		float: left;
		box-sizing: border-box;
		border-right: 1px solid #e3e4e3;
		border-bottom: 1px solid #e3e4e3;
		
	}
	.detailtable p{
		height: 37px;
		line-height: 37px;
		background-color: #e1f3fd;
		width: 100%;
		margin: 0;
		padding-left: 8px;
		color: #5b93c1;
		font-size: 16px;
		
	}
	.detailtable div{
		height: 36px;
		line-height: 36px;
		text-align: center;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		padding: 0 10px;
	}
	.detailtable .labelname{
		width: 8%;
	}
	.detailtable .biaoshi{
		text-align: left;
		
	}
</style>